import React, { useState, useEffect, useCallback, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';

// Use the live mode publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

//console.log("Stripe Publishable Key:", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function WaitlistForm() {
  const [waitlistMessage, setWaitlistMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleWaitlistSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const email = emailRef.current?.value;
    if (!email) return;

    try {
      const response = await fetch('https://processore.app/join-waitlist.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text();  // Log the raw response
      console.log("Raw response:", text);  // Log the raw response

      let data;
      try {
        data = JSON.parse(text);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        throw new Error("Invalid JSON response from server");
      }

      if (data.success) {
        setWaitlistMessage(data.message);
        setIsSuccess(true);
        if (emailRef.current) emailRef.current.value = '';
      } else {
        setWaitlistMessage(data.error || 'An error occurred. Please try again.');
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setWaitlistMessage('An unexpected error occurred. Please try again.');
      setIsSuccess(false);
    }
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold mb-4">Join Our Waiting List</h3>
      <ul className="list-disc list-inside mb-6">
        <li>Stay updated on development progress</li>
        <li>Be first to know about release dates</li>
        <li>Exclusive early-access opportunities</li>
      </ul>
      <form onSubmit={handleWaitlistSubmit} className="flex flex-col items-center">
        <input
          type="email"
          ref={emailRef}
          placeholder="Enter your email"
          className="px-4 py-2 rounded-full mr-2 border border-gray-300 w-full max-w-md mb-2"
          required
          autoComplete="email"
        />
        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full text-lg transition duration-300"
        >
          Join
        </button>
      </form>
      {waitlistMessage && (
        <p className={`mt-2 text-sm ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
          {waitlistMessage}
        </p>
      )}
    </div>
  );
}

function App() {
  const [error, setError] = useState<string | null>(null);
  const [waitlistMessage, setWaitlistMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!stripePromise) {
      console.error('Stripe failed to load');
      setError('Failed to load Stripe');
    }
  }, []);

  const handleCheckout = async () => {
    try {
      console.log('Starting checkout process');
      const response = await fetch('https://processore.app/create-checkout-session.php', {
        method: 'POST',
      });

      console.log('Response received:', response);
      const data = await response.json();
      console.log('Parsed data:', data);

      if (data.success && data.id && data.url) {
        window.location.href = data.url;
      } else {
        console.error('Error creating Checkout Session:', data.error);
        setError(data.error || 'An error occurred while creating the checkout session');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred. Please try again.');
    }
  };

  const scrollToWaitlist = () => {
    const waitlistElement = document.getElementById('waiting-list');
    if (waitlistElement) {
      waitlistElement.scrollIntoView({ behavior: 'smooth' });
      const emailInput = waitlistElement.querySelector('input[type="email"]');
      if (emailInput) {
        (emailInput as HTMLInputElement).focus();
      }
    }
  };

  const Home = ({ handleCheckout }: { handleCheckout: () => void }) => {
    const waitlistRef = useRef<HTMLDivElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (window.location.hash === '#waiting-list') {
        waitlistRef.current?.scrollIntoView({ behavior: 'smooth' });
        emailInputRef.current?.focus();
      }
    }, []);

    const handleWaitlistSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const email = emailInputRef.current?.value;
      if (!email) return;

      try {
        const response = await fetch('https://processore.app/join-waitlist.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
          setWaitlistMessage(data.message);
          setIsSuccess(true);
          if (emailInputRef.current) emailInputRef.current.value = '';
        } else {
          setWaitlistMessage(data.error || 'An error occurred. Please try again.');
          setIsSuccess(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setWaitlistMessage('An unexpected error occurred. Please try again.');
        setIsSuccess(false);
      }
    };

    return (
      <div className="bg-white text-gray-800 min-h-screen">
        <div className="container mx-auto px-4">
          <header className="flex justify-between items-center py-6">
            <div className="text-2xl font-bold">Process Ore AI</div>
            <nav>
              <a href="#features" className="mx-2 hover:text-blue-600">Features</a>
              <a href="#advantage" className="mx-2 hover:text-blue-600">Advantage</a>
              <a href="#roadmap" className="mx-2 hover:text-blue-600">Roadmap</a>
              <button onClick={handleCheckout} className="mx-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition duration-300">Pre-order for $99</button>
            </nav>
          </header>

          <main>
            <section className="text-center py-20">
              <h1 className="text-5xl font-bold mb-4">Process Ore AI: Revolutionizing Business Process Mapping</h1>
              <p className="text-xl mb-8">Create, Edit and Talk to Processes with AI</p>
              <p className="mb-8 max-w-2xl mx-auto">Process Ore AI is a powerful, AI-driven application for Mac and Windows that transforms how you create, edit, and optimize business process maps using the BPMN 2.0.2 standard.</p>
              <div className="flex justify-center space-x-4">
                <button onClick={handleCheckout} className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-full text-lg transition duration-300">Pre-order Now</button>
                <button onClick={scrollToWaitlist} className="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white px-6 py-3 rounded-full text-lg transition duration-300">Join Waiting List</button>
              </div>
              <div className="mt-12 relative">
                <video 
                  className="mx-auto rounded-lg shadow-2xl"
                  width="800"
                  height="450"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://processore.app/ProcessOreAI demo v1.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </section>

            <section id="features" className="py-20 bg-gray-100">
              <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <FeatureCard title="AI-Powered Process Creation" description="Generate process maps from prompts, images, or documents" />
                <FeatureCard title="Intelligent Editing" description="Refine your maps with AI suggestions and improvements" />
                <FeatureCard title="Comprehensive Documentation" description="Automatically create process documentation" />
              </div>
            </section>

            <section className="py-20">
              <h2 className="text-3xl font-bold text-center mb-8">Perfect for:</h2>
              <ul className="list-disc list-inside text-center max-w-2xl mx-auto">
                <li>Consultants streamlining client process discovery</li>
                <li>Project managers mapping software development workflows</li>
                <li>Business analysts preparing for process automation initiatives</li>
                <li>Solopreneurs trying to get their heads around their business processes</li>
              </ul>
            </section>

            <section id="advantage" className="py-20 bg-gray-100">
              <h2 className="text-3xl font-bold text-center mb-8">The Process Ore AI Advantage</h2>
              <p className="text-center mb-8 max-w-2xl mx-auto">Traditional process discovery is time-consuming and labor-intensive. Process Ore AI changes the game:</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <AdvantageCard number="1" title="Rapid Discovery" description="Convert meetings, transcripts, and documents into process maps instantly" />
                <AdvantageCard number="2" title="AI-Enhanced Analysis" description="Get intelligent suggestions for process improvements" />
                <AdvantageCard number="3" title="Artifact Generation" description="Create checklists and documentation with ease" />
                <AdvantageCard number="4" title="Versatile Applications" description="From business consulting to software project planning" />
              </div>
            </section>

            <section className="py-20">
              <h2 className="text-3xl font-bold text-center mb-8">Your Gateway to AI-Driven Process Automation</h2>
              <p className="text-center mb-8 max-w-2xl mx-auto">Process Ore AI bridges the gap between manual processes and the coming wave of automation:</p>
              <ul className="list-disc list-inside text-center max-w-2xl mx-auto">
                <li>Ideal for processes with incomplete logs or manual steps</li>
                <li>Complement to process mining and task mining tools</li>
                <li>Essential for preparing your business for the AI revolution in process management</li>
              </ul>
            </section>

            <section id="roadmap" className="py-20 bg-gray-100">
              <h2 className="text-3xl font-bold text-center mb-12">Our Roadmap</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Phase 1 (Coming Soon):</h3>
                  <ul className="list-disc list-inside">
                    <li>Text-to-process conversion</li>
                    <li>Speech-to-text-to-process functionality</li>
                    <li>Process-to-text documentation</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Future Phases:</h3>
                  <ul className="list-disc list-inside">
                    <li>Advanced business process management capabilities</li>
                    <li>Executable processes and automation conducting</li>
                  </ul>
                </div>
              </div>
            </section>

            <section id="waiting-list" ref={waitlistRef} className="py-20 text-center">
              <h2 className="text-3xl font-bold mb-8">Be Part of the Process Revolution</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Pre-order Now</h3>
                  <ul className="list-disc list-inside mb-6">
                    <li>Influence product development</li>
                    <li>Vote on upcoming features</li>
                    <li>Shape the future of process management</li>
                  </ul>
                  <button
                    onClick={handleCheckout}
                    className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-full text-lg transition duration-300"
                  >
                    Pre-order for $99
                  </button>
                </div>
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Join Our Waiting List</h3>
                  <ul className="list-disc list-inside mb-6">
                    <li>Stay updated on development progress</li>
                    <li>Be first to know about release dates</li>
                    <li>Exclusive early-access opportunities</li>
                  </ul>
                  <form onSubmit={handleWaitlistSubmit} className="flex flex-col items-center">
                    <input
                      type="email"
                      ref={emailInputRef}
                      placeholder="Enter your email"
                      className="px-4 py-2 rounded-full mr-2 border border-gray-300 w-full max-w-md mb-2"
                      required
                      autoComplete="email"
                    />
                    <button
                      type="submit"
                      className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full text-lg transition duration-300"
                    >
                      Join
                    </button>
                  </form>
                  {waitlistMessage && (
                    <p className={`mt-2 text-sm ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
                      {waitlistMessage}
                    </p>
                  )}
                </div>
              </div>
            </section>
          </main>

          <footer className="border-t border-gray-200 py-12 mt-20">
            <div className="flex justify-between items-center">
              <div className="text-2xl font-bold">Process Ore AI</div>
              <div className="flex space-x-4">
                <Link to="/privacy" className="hover:text-blue-600">Privacy</Link>
                <Link to="/terms" className="hover:text-blue-600">Terms</Link>
                <Link to="/contact" className="hover:text-blue-600">Contact</Link>
              </div>
            </div>
            <div className="mt-8 text-center text-gray-500">
              <p>&copy; 2024 Process Ore AI. All rights reserved.</p>
              <p>Process Ore AI: Mapping the Future of Business Processes</p>
            </div>
          </footer>
        </div>
      </div>
    );
  };

  interface FeatureCardProps {
    title: string;
    description: string;
  }

  const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  interface AdvantageCardProps {
    number: string;
    title: string;
    description: string;
  }

  const AdvantageCard: React.FC<AdvantageCardProps> = ({ number, title, description }) => (
    <div className="text-center">
      <div className="text-4xl font-bold text-blue-600 mb-2">{number}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  const Success = () => (
    <div className="bg-white text-gray-800 min-h-screen flex items-center justify-center">
      <div className="text-center max-w-2xl mx-auto p-6">
        <h1 className="text-4xl font-bold mb-6">Thank You for Your Purchase!</h1>
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6" role="alert">
          <p className="font-bold">Payment Successful</p>
          <p>Your pre-order for Process Ore AI has been confirmed.</p>
        </div>
        <p className="text-xl mb-4">We're excited to have you on board as an early supporter of Process Ore AI!</p>
        <p className="mb-6">You will receive an email shortly with all the details of your purchase and information about next steps.</p>
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-2xl font-semibold mb-2">What's Next?</h2>
          <ul className="list-disc list-inside text-left">
            <li>Check your email for the purchase confirmation</li>
            <li>Join our community forum to connect with other early adopters</li>
            <li>Stay tuned for updates on the development progress</li>
            <li>Get ready to revolutionize your business process mapping!</li>
          </ul>
        </div>
        <a 
          href="/" 
          className="inline-block mt-8 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Return to Home
        </a>
      </div>
    </div>
  );

  const Cancel = () => (
    <div className="bg-white text-gray-800 min-h-screen">
      <header className="container mx-auto px-4 flex justify-between items-center py-6">
        <Link to="/" className="text-2xl font-bold">Process Ore AI</Link>
        <nav>
          <Link to="/" className="mx-2 hover:text-blue-600">Home</Link>
          <Link to="/#features" className="mx-2 hover:text-blue-600">Features</Link>
          <Link to="/contact" className="mx-2 hover:text-blue-600">Contact</Link>
          <button onClick={handleCheckout} className="mx-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full transition duration-300">Pre-order</button>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold mb-6">Payment Canceled</h1>
        <p className="mb-8">Your payment was not completed. You can try again or join our waiting list.</p>
        <div className="flex flex-col items-center space-y-4">
          <button 
            onClick={handleCheckout}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full text-lg transition duration-300"
          >
            Pre-order for $99
          </button>
          <Link 
            to="/#waiting-list" 
            className="text-blue-600 hover:underline"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/#waiting-list';
              setTimeout(scrollToWaitlist, 100);
            }}
          >
            Join Waiting List
          </Link>
        </div>
      </main>

      <footer className="border-t border-gray-200 py-12 mt-20">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-2xl font-bold">Process Ore AI</div>
          <div className="flex space-x-4">
            <Link to="/privacy" className="hover:text-blue-600">Privacy</Link>
            <Link to="/terms" className="hover:text-blue-600">Terms</Link>
            <Link to="/contact" className="hover:text-blue-600">Contact</Link>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Process Ore AI. All rights reserved.</p>
          <p>Process Ore AI: Mapping the Future of Business Processes</p>
        </div>
      </footer>
    </div>
  );

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route path="/" element={<Home handleCheckout={handleCheckout} />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home handleCheckout={handleCheckout} />} />
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;